//// Custom.scss
//// Option A: Include all of Bootstrap
//
//// Include any default variable overrides here (though functions won't be available)
//
//@import "../node_modules/bootstrap/scss/bootstrap.scss";
//
//// Then add additional custom code here

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
//$primary: #edad6a;
$primary: #D4981A;

$navbar-light-active-color: $primary;
$navbar-toggler-focus-width: 0;

$accordion-button-active-bg: $primary;
$accordion-button-color: white;
$accordion-button-bg: $primary;
$accordion-button-active-color: white;
$accordion-icon-color: white;
$accordion-icon-active-color: white;
$accordion-button-focus-box-shadow: none;

  // 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "node_modules/bootstrap/scss/variables";
//@import "node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/helpers";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here

@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";


@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');
}

* {
  font-family: 'Montserrat';
}

.navbar-toggler {
  &:focus {
    border-color: $primary;
  }
}

header {
  background: var(--bs-white);
}

section {
  padding-top: 64px;
  padding-bottom: 64px;

  &.azedcet {
    position: relative;
    min-height: 100vh;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      background-position: center;
      background-size: cover;
      z-index: -1;
      background-image: url('./assets/images/webp/pexels-juan-cruz-palacio-mir-3525541.webp');
      filter: brightness(0.2);
    }

    h1 {
      letter-spacing: 1.325rem;
      padding-left: 1.325rem;
    }

    .catch-phrase {
      font-family: cursive;
      letter-spacing: 0.1rem;
    }
  }

  &.notre-expertise {
    .card {
      border: none;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .card-img-overlay {
        background-color: rgba(0, 0, 0, 0.4);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
      }
    }
  }

  ul {
    list-style: square;

    ul {
      list-style: circle;
    }
  }
}

